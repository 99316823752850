<template>
  <v-col cols="12" sm="12">
    <v-row>
      <v-col cols="12" sm="3" md="2" class="pa-5">
        <v-img @click="fetchCrewInfos()" src="../../assets/img/black_fire_crew-600.png"/>
      </v-col>
      <v-col cols="12" sm="9" md="10" style="max-height: 300px">
        <v-chip color="primary" class="font-led black--text pa-5 ma-2" style="font-size:20px;">
          {{ countCrewMembers }} membres
        </v-chip>
        <template v-for="(user, n) in crewMembers">
          <v-chip :key="user._id" color="black" dense v-if="n < 50"
                  :class="classOnline(user)" class="px-0 pr-2 ma-1 crewchip">
              <v-gravatar height="28" class="pa-1" style="border-radius:50%;"
                                :default-img="'retro'" :email="user.name"/>
              {{ user.name }}
          </v-chip>
        </template>
        <v-chip v-if="countCrewMembers > 50" color="black" class="" style="font-size:20px;">
          ...
        </v-chip>
      </v-col>
    </v-row>

    <v-row>
      <v-divider></v-divider>
    </v-row>

    <v-row>
      <v-col cols="12" sm="5" md="5" class="">
        <v-card color="black" class="pa-3">
          <v-btn color="primary" class="black--text mb-4" @click="openPopupFormMsg">
            <v-icon>mdi-file-plus</v-icon> Nouveau message
          </v-btn>
          <template v-for="(crewMsg, i) in crewMessages">
            <v-list :key="i" color="black" class="pa-0">
              <v-list-item two-line class="list-item-inbox"  @click="currentMsg = crewMsg" >
                <v-list-item-content>
                  <v-list-item-title>{{ i+1 }}# {{ crewMsg.subject }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <b :class="crewMsg.status == 'READY' ? 'primary--text' : 'green--text'">{{ crewMsg.status }}</b> • 
                    <div class="pr-1 d-inline-block" v-if="crewMsg.status == 'SENT'">Envoyé à {{ crewMsg.to.length }} membres • </div> 
                    <time-ago :datetime="crewMsg.updated" :refresh="60" long 
                              :locale="$vuetify.lang.current"></time-ago>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </v-card>
      </v-col>
      <v-col cols="12" sm="7" md="7" class="pl-0">
        <v-card color="black" class="pa-4" v-if="currentMsg != null">
          <v-card-title class="pl-0"><b>Objet : </b> {{ currentMsg.subject }}</v-card-title>
          <br>
          <b :class="currentMsg.status == 'READY' ? 'primary--text' : 'green--text'">{{ currentMsg.status }}</b> • 
          <div class="pr-1 d-inline-block" v-if="currentMsg.status == 'SENT'">Envoyé à {{ currentMsg.to.length }} membres • </div> 
          <time-ago :datetime="currentMsg.updated" :refresh="60" long 
                    :locale="$vuetify.lang.current"></time-ago>
          
          <v-divider class="mt-3"></v-divider>
          <v-row class="my-0">
            <v-toolbar elevation="0" color="transparent">
              <v-btn v-if="currentMsg.status == 'READY'" 
                    color="primary" outlined class="mr-3"
                    @click="editMessage()">
                <v-icon>mdi-pencil</v-icon> Modifier
              </v-btn>
              <v-btn v-if="currentMsg.status == 'READY'" 
                    color="primary" class="black--text"
                    @click="sendMessage()">
                <v-icon>mdi-send</v-icon> Envoyer le message
              </v-btn>
              <v-btn v-else color="blue" outlined @click="openDialogComments()">
                <v-icon small class="mr-1">mdi-comment</v-icon> Afficher les commentaires
              </v-btn>
            </v-toolbar>
          </v-row>
          <v-divider></v-divider>

          <v-card-text class="px-0" v-html="nl2br(currentMsg.message)"/>

        </v-card>
      </v-col>
    </v-row>


    <v-dialog scrollable v-model="showPopupFormMsg" max-width="650">
      <v-card>

        <v-toolbar color="transparent" elevation="0">
          <v-icon color="primary" class="mr-2">mdi-pencil</v-icon> 
          Envoyer un nouveau message au Black Faya Crew
        </v-toolbar>

        <v-divider/>

        <v-card-text class="mt-5">
          <v-text-field  outlined v-model="newMsg.subject"
                        label="Objet du message">
          </v-text-field>

          <v-textarea  outlined v-model="newMsg.message"
                        label="Texte du message" rows="15">
          </v-textarea>
        </v-card-text>
        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn color="" text @click="showPopupFormMsg=false" >
            <v-icon small>mdi-close</v-icon> Annuler
          </v-btn>
          <v-btn color="blue" dark @click="saveMessage()" >
            <v-icon small>mdi-content-save</v-icon> Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DialogComments/>
  </v-col>
</template>

<style>
  .crewchip.online{
    border: 2px solid green !important;
  }
  .crewchip.present{
    border: 2px solid orange !important;
  }
  .crewchip.absent{
    border: 2px solid black !important;
  }
  .countCrewMembers{
    
    color:white;
  }
  .list-item-inbox{
    border-top:1px solid rgb(34, 34, 34)
  }
</style>

<script>

import axios from 'axios'
import Gravatar from 'vue-gravatar'
import {TimeAgo} from "vue2-timeago"

import DialogComments from '@/components/admin/fayacrew/DialogComments.vue'

export default {
  name: 'faya-crew',
  components: { 'v-gravatar' : Gravatar, TimeAgo, DialogComments },
  data: () => ({
    crewMembers: [],
    countCrewMembers: 0,
    showPopupFormMsg: false,
    currentMsg: null,
    crewMessages: [],
    newMsg : {},
    initMsg : {
      subject: "",
      message: ""
    }
  }),
  async mounted(){
    this.initForm()
    this.fetchCrewInfos()
  },
  methods: {
    async fetchCrewInfos(){
      let res = await axios.get("data/get-crew-infos")
      this.crewMembers = res.data.crewMembers
      this.countCrewMembers = res.data.countCrewMembers
      this.crewMessages = res.data.crewMessages
    },
    classOnline(user){
      let dateNow = new Date()

      let dateOnline = new Date(dateNow)
      dateOnline.setMinutes(dateNow.getMinutes() - 3)

      let datePresent = new Date(dateNow)
      datePresent.setMinutes(dateNow.getMinutes() - 60)

      let dateUser = new Date(user.updated)

      // console.log("dates", user.name, dateUser, dateNow)
      // console.log("dates", user.name, dateUser, dateOnline)
      // console.log("dates", user.name, dateUser, datePresent)
      // console.log("dates**************", dateUser > dateOnline)
      if(dateUser > dateOnline) return "online"
      if(dateUser > datePresent) return "present"
      
      return "absent"
    },
    initForm(){
      this.newMsg = this.initMsg 
    },
    openPopupFormMsg(){
      this.initForm()
      this.showPopupFormMsg = true 
    },
    saveMessage(){
      axios.post("admin/save-crew-msg", { newMsg : this.newMsg })
           .then((res)=>{
            if(!res.data.error){
              this.initForm()
              this.showPopupFormMsg = false 
              this.fetchCrewInfos()
              this.$store.dispatch('app/openSnackbar', 
                                  { show: true, color:'green', 
                                    title: "Le message a bien été enregistré",
                                    text: "Il ne reste plus qu'à l'envoyer.",
                                    icon: 'thumb-up',
                                    top: true, 
                                    path: null })
            }
           })
    },
    sendMessage(){
      if(this.currentMsg == null) return 
      axios.post("admin/send-crew-msg", { msgId : this.currentMsg._id })
           .then((res)=>{
            if(!res.data.error){
              this.initForm()
              this.showPopupFormMsg = false 
              this.currentMsg = res.data.message
              this.fetchCrewInfos()
              this.$store.dispatch('app/openSnackbar', 
                                  { show: true, color:'green', 
                                    title: "Le message a bien été envoyé",
                                    text: "Le Black Faya Crew a du pain sur la planche !",
                                    icon: 'thumb-up',
                                    top: true, 
                                    path: null })
            }
           })
    },
    openDialogComments(){
      console.log("openDialogComments")
      this.$root.$emit("openDialogComments", { idCrewMsg: this.currentMsg._id })
    },
    nl2br: function(str, is_xhtml){
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      
      return newStr
    },
  },
  computed: {    
  },
  watch: {
     
  }, 
}
</script>
